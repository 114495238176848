import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['pandemicQuizQuestion1', 'pandemicQuizQuestion2', 'pandemicQuizQuestion3', 'pandemicQuizQuestion4', 'pandemicQuizQuestion5'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "quiz"
    }}>{`Quiz`}</h2>
    <Paper mdxType="Paper">
  <Quiz id="pandemicQuizQuestion1" once={true} question="QUESTION 01: I should try to avoid talking with my teen about the pandemic, because they already hear so much about it." fallbackResponse={<p>
        The correct answer is: <b>False</b>. Even if your teen hears plenty about the pandemic from other people and the
        media, it’s still important for you to talk with them about it. You can help them filter information they
        receive from other places. Checking in with your teen, especially as the local situation changes, can also help
        them to understand and cope with the rapidly-changing situation. See{' '}
        <GoTo to="/m2-parenting-in-pandemic/07-have-conversation" mdxType="GoTo">Having the conversations</GoTo> and{' '}
        <GoTo to="/m2-parenting-in-pandemic/08-address-misconceptions" mdxType="GoTo">Addressing misconceptions</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="pandemicQuizQuestion2" once={true} question="QUESTION 02: Even if the whole family is spending lots of time together at home, it’s important to spend regular one-on-one time with my teen." fallbackResponse={<p>
        The correct answer is: <b>True</b>. It might feel like you are spending plenty of time with your teen if you are
        all together at home more than usual. Even if this is the case, your teen still needs opportunities to have your
        undivided attention. Try to spend regular, one-on-one time with your teen, even during lockdowns. You might need
        to be creative and involve your teen in planning what you can do together! See{' '}
        <GoTo to="/m2-parenting-in-pandemic/13-connection" mdxType="GoTo">Helping your teen to stay connected</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="pandemicQuizQuestion3" once={true} question="QUESTION 03: If my teen is anxious about the pandemic, talking with them about my own worries is helpful because they will feel understood." fallbackResponse={<p>
        The correct answer is: <b>False</b>. When discussing your teen’s worries about the pandemic, try to use a
        reassuring tone and remain calm yourself. It’s important to manage your own anxiety before talking with your
        teen, so that you don’t reinforce your teen’s anxiety. If you need to, try to use helpful coping strategies that
        work for you. See <GoTo to="/m2-parenting-in-pandemic/07-have-conversation" mdxType="GoTo">Having the conversations</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="pandemicQuizQuestion4" once={true} question="QUESTION 04: If my teen is not following the local regulations and restrictions, I should first try to understand why they aren’t following the rules, before trying to find a solution." fallbackResponse={<p>
        The correct answer is: <b>True</b>. In order to support your teen to follow the local regulations and
        restrictions, it’s important to have a good understanding of what’s getting in the way. If you have a good
        understanding of the problem, you will be better able to work with your teen to problem-solve any barriers to
        following the rules. See{' '}
        <GoTo to="/m2-parenting-in-pandemic/09-follow-restrictions" mdxType="GoTo">
          Helping your teen follow the local regulations and restrictions
        </GoTo>
        .
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="pandemicQuizQuestion5" once={true} question="QUESTION 05: Some teenagers will experience increased anxiety as the local restrictions ease, for example when they go back to school after a lockdown or period of online learning." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Some teenagers will have higher levels of anxiety when the restrictions
        ease, for example returning to face-to-face school. The uncertainty of constantly-changing local rules can be
        unsettling for some teens, who may struggle to adjust. Remember to check in with your teen as the local
        situation changes. See{' '}
        <GoTo to="/m2-parenting-in-pandemic/12-easing-restrictions" mdxType="GoTo">Supporting your teen as restrictions ease</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <SingleImage smallGridSize={8} gridSize={4} src="/images/shared/51.1-01.svg" alt="thinking woman" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      